import React from 'react';

import TableHeader from './TableHeader';
import TableRow from './TableRow';
import TableRowPump from './TableRowPump';
import Button from 'components/Button';

import { BUTTON_VARIANTS } from 'components/Button/constants';

const MarketTableTon = ({ data, onLoadMore, isShowLoadingButton }) => {
  return (
    <React.Fragment>
      <TableHeader />
      {data?.result?.map((item, index) => {
        const isPumpFun = item.mint?.includes('pump');

        if (isPumpFun) {
          return <TableRowPump key={index} {...item} />;
        }
        return <TableRow key={index} {...item} />;
      })}
      {isShowLoadingButton && (
        <Button
          className="mt-3"
          onClick={onLoadMore}
          variant={BUTTON_VARIANTS.TABLE_MORE}
          text="More"
        />
      )}
    </React.Fragment>
  );
};

export default MarketTableTon;
