import React from 'react';
import clsx from 'clsx';

const Avatar = ({ className, imageSrc, ...props }) => {
  return (
    <div className={clsx('flex flex-shrink-0', className)} {...props}>
      <img
        className="w-11 h-11 rounded-[50%]"
        src={imageSrc || '/defaultAvatar.png'}
        alt="avatar image"
      />
    </div>
  );
};

export default Avatar;
