export const SOCIALS = [
  {
    link: 'https://discord.gg/n8DUKWwXj9',
    title: '',
    image: '/socials/discord.svg'
  },
  {
    link: 'https://t.me/rrrocketlauncher',
    title: '',
    image: '/socials/telegram.svg'
  },
  {
    link: 'https://www.tiktok.com/@rrrocketlauncher?_t=8myFau1s05K&_r=1',
    title: '',
    image: '/socials/tiktok.svg'
  },
  {
    link: 'https://x.com/Rocketlnchr',
    title: '',
    image: '/socials/x.svg'
  }
];
