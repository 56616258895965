import React, { useState } from 'react';
import clsx from 'clsx';

import Avatar from '../TableAvatar';
import Typography from 'components/Typography';
import TableTag from 'components/Tags/TableTag';
import ComingSoonModal from 'components/Modals/ComingSoonModal';
import InfoModal from '../InfoModal';
import { getToFixedNumber } from 'helpers/number';
import { useBlurBackground } from 'helpers/hooks/useBlurBackground';
import { truncateString } from 'helpers/string';

const TableRowPump = ({
  className,
  priceUsdChange24h,
  mint,
  imageUri,
  marketCap,
  marketCapSol,
  solPriceUsd,
  volumeUsd24h,
  name,
  priceUsd,
  creator
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const [isOpenedInfo, setIsOpenedInfo] = useState(false);
  const { setBlurBackground } = useBlurBackground();

  const infoForModal = {
    mint,
    imageUri,
    marketCap,
    volumeUsd24h,
    name,
    priceUsd,
    priceUsdChange24h
  };

  const onRowClickHandler = (e) => {
    const isImageClick = e.target.className.includes('scanImage');
    if (isImageClick) return;
    setBlurBackground();
    setIsOpened(true);
  };

  const onOpenedInfoModal = () => {
    setBlurBackground();
    setIsOpenedInfo(true);
  };

  return (
    <React.Fragment>
      <div
        onClick={onRowClickHandler}
        className={clsx(
          'sm:hidden relative cursor-pointer group flex w-full justify-between mt-3 px-4 py-3 items-center bg-white-10 hover:bg-white-50 rounded-2xl border border-white-100 space-x-4',
          className
        )}>
        <div className="flex-1 flex items-center">
          <Avatar imageSrc={imageUri} />
          <Typography
            className="ml-3 text-white-1000 text-ellipsis w-[100px] overflow-hidden"
            text={name}
          />
        </div>
        <Typography
          className="text-white-1000 flex-1"
          text={`$${getToFixedNumber((marketCapSol * solPriceUsd) / 1e9, 9)}`}
        />
        <Typography
          className="text-white-1000 flex-1"
          text={`$${getToFixedNumber(marketCapSol * solPriceUsd, 2)}`}
        />
        <Typography
          className="text-white-1000 flex-1"
          text={`$${getToFixedNumber(volumeUsd24h)}`}
        />
        <div className="flex justify-start items-center flex-1">
          <TableTag text={getToFixedNumber(priceUsdChange24h)} />
        </div>
        <Typography className="text-white-1000 flex-1 underline" text={truncateString(creator)} />
        <div className="flex flex-1 items-center">
          <a
            target="_blank"
            className="flex items-center space-x-[5px]"
            href={`https://pump.fun/${mint}`}>
            <img
              className="h-7 w-7 scanImage"
              src="/icons/dex/pumpfun.svg"
              alt="pumpfun link image"
            />
          </a>
        </div>
        <img
          className="hidden group-hover:block absolute top-1/2 -translate-y-1/2 right-4 w-[44px] h-[44px]"
          src="/icons/circleRightArrow.svg"
          alt="about this token icon"
        />
      </div>

      <div
        onClick={onOpenedInfoModal}
        className={clsx(
          'hidden relative cursor-pointer sm:flex sm:flex-col w-full mt-3 px-4 py-3 bg-white-10 hover:bg-white-50 rounded-2xl border border-white-100 space-x-4',
          className
        )}>
        <div className="sm:flex justify-evenly">
          <div className="flex-1 flex items-center space-x-2">
            <Avatar className="w-10 h-10" imageSrc={imageUri} />
            <div className="flex flex-col items-start justify-between space-y-1">
              <Typography
                className="text-white-1000 text-ellipsis w-[100px] overflow-hidden"
                text={name}
              />
              <Typography
                className="text-white-500 flex-1"
                text={`$${getToFixedNumber(priceUsd, 4)}`}
              />
            </div>
          </div>
          <div className="flex flex-col items-end justify-between space-y-1">
            <div className="flex justify-start items-center flex-1">
              <TableTag text={getToFixedNumber(priceUsdChange24h)} />
            </div>
            <Typography
              className="text-white-1000 flex-1"
              text={`$${getToFixedNumber(marketCap)}`}
            />
          </div>
        </div>
      </div>
      {isOpened && <ComingSoonModal isOpened={isOpened} setIsOpened={setIsOpened} />}
      {isOpenedInfo && (
        <InfoModal info={infoForModal} isOpened={isOpenedInfo} setIsOpened={setIsOpenedInfo} />
      )}
    </React.Fragment>
  );
};

export default TableRowPump;
