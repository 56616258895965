export const TYPOGRAPHY_VARIANTS = {
  HEADER_H1: 'HEADER_H1',
  HEADER_H2: 'HEADER_H2',
  HEADER_H3: 'HEADER_H3',
  HEADER_H4: 'HEADER_H4',
  BODY_H2: 'BODY_H2',
  BODY_S: 'BODY_S',
  BODY_X: 'BODY_X',
  BODY_L: 'BODY_L',
  BODY_M: 'BODY_M',
  BODY_XS: 'BODY_XS',
  XS: 'XS',
  BUTTON_L: 'BUTTON_L',
  BUTTON_M: 'BUTTON_M',
  CAPTION: 'CAPTION'
};
